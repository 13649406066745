import React from 'react'
import "./HomeScreen.css";
import Nav from "../components/Nav.js";
import SocialFollow from "../components/SocialFollow.js";
import Copyright from '../components/Footer/Copyright';
import GoToTop from '../GoToTop';
/* import { features, portfolio, team } from "../components/datos.js";
import arrowDown from "../assets/arrow-down.svg" */
/* import styled from "styled-components"; */
import { InlineWidget } from "react-calendly";
/* import { Link } from "react-scroll"; */
import { Helmet } from "react-helmet-async";

function Calendly() {
    return (
        <>
        <Helmet>
            <title>We build disruptive Corporate-Startups</title>
            <meta
                name="title"
                content="We build disruptive Corporate-Startups"
            />
            <meta
                name="description"
                content="venture capital to build disruptive corporate-startups"
            />
            <link
                rel='canonical'
                href='/'
            />
            <meta 
                name='image' 
                content="https://firebasestorage.googleapis.com/v0/b/unicornia-website.appspot.com/o/unicornia-cover01.png?alt=media&token=3b5cb943-fad2-4df7-a8b3-6191f26a4a18"
            />
        </Helmet>
        
        <div>
        <Nav />

        <div className="calendly" id="calendly" >
            <h3>Book a videocall with us!</h3>
            <p className='calendly__wsp' >WhatsApp: +51 981 166 709</p>
            <p className='calendly__email'>Email: unicornia.ventures@gmail.com</p>
            <InlineWidget url="https://calendly.com/unicornia-ventures" />
        </div>

        <div className="footer" id="footer" >
            <SocialFollow/>
            <Copyright/>
            <GoToTop />
        </div>

    </div>
    </>
    )
}

export default Calendly;
