import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import './App.css';
import Calendly from './screens/Calendly';
import HomeScreen from './screens/HomeScreen';
import PdfMorefood from './components/Portfolio/morefood';
import PdfSuperpix from './components/Portfolio/superpix';

function App() {
  return (
    <div className="app">
    <Router onUpdate={() => window.scrollTo(0, 0)}>
      {
        <Routes>
          <Route exact path="/superpix" element={<PdfSuperpix />} />
          <Route exact path="/morefood" element={<PdfMorefood />} />
          <Route exact path="/calendly" element={<Calendly />} />
          <Route exact path="/" element={<HomeScreen />} />
        </Routes>
      }
      </Router>
    </div>
  );
}

export default App;
