import React from 'react';
import "./Copyright.css";

function Copyright() {
    return (
        
    <div className="copyright">
        <p>&#123; Uniqornio 2021-2024 &#9679; venture capital to build disruptive corporate-startups &#x7D;</p>
        <p>&#123; Legally represented in Latin America by Insighter del Perú S.A.C. &#x7D;</p>
    </div>
        
    )
}

export default Copyright;
