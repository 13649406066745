import React from 'react';
import { useNavigate } from "react-router-dom";
import "./HomeScreen.css";
import Nav from "../components/Nav.js";
import SocialFollow from "../components/SocialFollow.js";
import Copyright from '../components/Footer/Copyright';
import { features, portfolio, team } from "../components/datos.js";
import arrowDown from "../assets/arrow-down.svg"
import styled from "styled-components";
import { Link } from "react-scroll";
import { Helmet } from "react-helmet-async";

const Slide = styled.div`
  align-items: center;
  background-color: #${(props) => props.bg};
  color: #${(props) => props.font};
`;

function HomeScreen() {
    const navigate = useNavigate();

    const calendly = (e) => {
        e.preventDefault();
        navigate('/calendly');
    };

    return (
        <>
        <Helmet>
            <title>We build disruptive Corporate-Startups</title>
            <meta
                name="title"
                content="We build disruptive Corporate-Startups"
            />
            <meta
                name="description"
                content="venture capital to build disruptive corporate-startups"
            />
            <link
                rel='canonical'
                href='/'
            />
            <meta 
                name='image' 
                content="https://firebasestorage.googleapis.com/v0/b/uniqornio-com.appspot.com/o/resources%2Funiqornio-cover01.png?alt=media&token=c23db3b7-39d2-4d62-8cce-d0259a1f1a99"
            />
        </Helmet>
        
        <div>
        <Nav />
        <header id="uniqornio" className="banner"
        >
    
        <div className="banner__frame">
            <div className="banner__content">
                <h1 className="banner__mensaje">
                WE WILL BUILD YOUR NEXT STARTUP
                </h1>
                <h2 className="banner__submensaje">
                From zero to IPO.
                </h2>
                <h2 className="banner__submensaje">
                For people & corporates.
                </h2>
                <Link to="calendly" smooth={true} duration={500} >
                    <button 
                    onClick={calendly}
                    className="banner__contacto">
                    Get a videocall
                    </button>
                </Link>
            </div>

            <div className="banner__image">
                <img src='https://firebasestorage.googleapis.com/v0/b/uniqornio-com.appspot.com/o/resources%2Funiqornio-cover01.png?alt=media&token=c23db3b7-39d2-4d62-8cce-d0259a1f1a99' alt='' className="banner__image__item" />
            </div>
        </div>
        </header>

        <div className='features__title' >
            <h2>Why create a Startup in your company?</h2>
        </div>

        <div className='features' >
            {features.map((item) => (
                <div className='features__container' >
                    <img src={item.img} alt='' className='features__img' />
                    <p className='features__desc' > {item.descEN} </p>
                </div>
            ))}
        </div>

        <div className='we' >
            <h2>We are a venture-builder.<br/>
            <strong>We are a startup factory.</strong>
            <br/>We are disruptors creating Deep-Tech & DTC Startups to solve real problems worldwide.</h2>
            <div className='we__portfolio' id="portfolio" >
                <h3>Look what we have built.</h3>
                <img src={arrowDown} alt='' />
            </div>
        </div>

        <div className='portfolio' >
            {portfolio.map((datos) => (
            <Slide font={datos.font} bg={datos.bg} key={datos.id}>
            <div className='portfolio__container'>
                <div className='portfolio__left'>
                    <img src={datos.logo} alt={datos.title} />
                    <p> { datos.desc } </p>
                    <button >
                    <a href={datos.www} target="_blank" rel="noopener noreferrer" >{datos.textwww}</a>
                    </button>
                </div>
                <div className='portfolio__right'>
                    <img src={datos.img} alt='' />
                </div>
            </div>
            </Slide>
            ))}
        </div>

        <div className='team__title' id="team">
            <h2>Who's the team?</h2>
        </div>

        <div className='team' >
            {team.map((equipo) => (
                <div className='team__container' key={equipo.id} >
                    <img src={equipo.img} alt='' className='team__img' />
                    <p className='team__name' > {equipo.name} </p>
                    <p className='team__title' > {equipo.title} </p>
                    <p className='team__desc' > {equipo.desc} </p>
                    <button className='team__linkedin'><a href={equipo.linkedinUrl} target="_blank" rel="noopener noreferrer" >See my LinkedIn</a></button>
                </div>
            ))}
        </div>

        <div className="footer" id="footer" >
            <SocialFollow/>
            <Copyright/>
        </div>

    </div>
    </>
    )
}

export default HomeScreen;
