import React from 'react';
import PDFfile from "../../assets/MoreFood-PitchDeck-web.pdf"

const PdfMorefood = () => {
  const mobileDevice = /Mobi|Android/i.test(navigator.userAgent);

  let pdfUrl = PDFfile;
  if (mobileDevice) {
    pdfUrl += '?embedded=true';
  }

  return (
    <div style={{ height:"100vh", width:"100%" }} >
      <iframe src={pdfUrl} width="100%" height="100%" title="MoreFood::Pitch Deck" />
    </div>
  );
};

export default PdfMorefood;